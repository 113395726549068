import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Theme from '../../Theme';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {groupStudyHoursByYear} from '../../Components/TwpaaUserStudyHoursButton';
import {renderCategoryOption} from '../../Components/TwpaaCourseItem';

const pageSize = 20;
const pageRoute = '/profile/study-hour/';
const collectionName = 'study_hour';

const hoursTypeOptions = [
  {
    name: 'option_1',
    label: '會員在職進修獎勵時數',
  },
  {name: 'option_2', label: '專利師在職進修時數'},
  {name: 'option_3', label: '專利訴訟業務相關進修時數'},
  {name: 'option_4', label: '商標進修時數'},
];

function ProfileStudyHour(props) {
  const [user] = useOutlet('user');
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const maxYear = new Date().getFullYear() + 1;
  const minYear = maxYear - 10;
  const [selectedYear, setSelectedYear] = React.useState(maxYear - 1);
  const [selectedHoursType, setSelectedHoursType] = React.useState('');

  const years = [];
  let y = maxYear;
  while (y >= minYear) {
    years.push(y--);
  }

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {
        id_card_number: user.data?.id_card_number,
        date: {$regex: selectedYear.toString()},
      };

      if (selectedHoursType) {
        extraQuery[`hours_catagory.${selectedHoursType}`] = true;
      }

      const resp = await JStorage.fetchDocuments(
        collectionName,
        extraQuery,
        ['-date'],
        paging,
      );

      resp.results = groupStudyHoursByYear(resp.results);
      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging, user, selectedYear, selectedHoursType]);

  React.useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList]);

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>進修時數紀錄</h1>

      <div style={{marginBottom: 20, display: 'flex', gap: 10}}>
        <Ant.Select value={selectedYear} onChange={setSelectedYear}>
          {years.map((it) => {
            return (
              <Ant.Select.Option key={it} value={it}>
                {it}
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>

        <Ant.Select
          value={selectedHoursType}
          onChange={setSelectedHoursType}
          style={{width: 200}}>
          {[
            {
              name: '',
              label: '不指定時數類型',
            },
            ...hoursTypeOptions,
          ].map((it) => {
            return (
              <Ant.Select.Option value={it.name}>{it.label}</Ant.Select.Option>
            );
          })}
        </Ant.Select>

        <Ant.Button
          type="link"
          onClick={() => {
            window.open(
              `/print-preview/study-hours?year=${selectedYear}&type=${selectedHoursType}`,
            );
          }}>
          預覽列印
        </Ant.Button>
      </div>

      <Ant.Table
        columns={[
          {
            title: '日期',
            key: 'date',
            dataIndex: 'date',
          },
          {
            title: '活動名稱',
            key: 'course_name',
            render: (_, record) => {
              if (record.year) {
                return (
                  <div style={{textAlign: 'right', fontWeight: 'bold'}}>
                    {record.year}年小計
                  </div>
                );
              }
              return record.course_name;
            },
          },
          {
            title: '時數',
            key: 'hours',
            dataIndex: 'hours',
          },
          {
            title: '時數類別',
            key: 'category',
            render: (_, record) => {
              if (record.sums) {
                return (
                  <div>
                    {Object.keys(record.sums).map((k) => {
                      const labels = {
                        option_1: '獎',
                        option_2: '職',
                        option_3: '訴',
                        option_4: '商',
                      };
                      return (
                        <div key={k}>
                          {labels[k]}/{record.sums[k]}
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return (
                <CourseCat>
                  {record.hours_catagory?.option_1 &&
                    renderCategoryOption('option_1')}
                  {record.hours_catagory?.option_2 &&
                    renderCategoryOption('option_2')}
                  {record.hours_catagory?.option_3 &&
                    renderCategoryOption('option_3')}
                  {record.hours_catagory?.option_4 &&
                    renderCategoryOption('option_4')}
                </CourseCat>
              );
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

const CourseCat = styled.ul`
  display: flex;
  gap: 6px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }
`;

export default withPageEntry(ProfileStudyHour);
